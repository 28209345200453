import React from 'react';
import styled from 'styled-components';
import {Input} from 'antd';

function AdminLegacyTimeDisplayWidget(props) {
  const {value, schema} = props;

  const nextValue = (() => {
    if (props.value) {
      if (schema.display === 'date') {
        return value.slice(0, 10);
      } else if (schema.display === 'time') {
        return value.slice(0, 5);
      } else if (schema.display === 'date_time') {
        return value.slice(0, 16);
      }
    }

    return '';
  })();

  return (
    <Wrapper>
      <Input disabled value={nextValue} />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminLegacyTimeDisplayWidget;
