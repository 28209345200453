import React from 'react';
import styled from 'styled-components';
import {Table} from 'antd';
import AdminLineDivider from '../../Components/AdminLineDivider';

const REVIEW_COLUMNS = [
  {
    title: '職稱',
    key: 'SIGN_USER.POSITION',
    dataIndex: 'SIGN_USER.POSITION',
    render: (_, r) => {
      switch (r.SIGN_USER?.POSITION) {
        case 'none':
          return '無';
        case 'A01':
          return '理事長';
        case 'A02':
          return '常務理事';
        case 'A03':
          return '理事';
        case 'A04':
          return '常務監事';
        case 'A05':
          return '監事';
        case 'A06':
          return '全聯會代表';
        case 'B01':
          return '主任委員';
        case 'B02':
          return '副主任委員';
        case 'B03':
          return '委員';
        case 'B04':
          return '會員';
        case 'B05':
          return '執行長';
        case 'B06':
          return '顧問';
        case 'C01':
          return '秘書長';
        case 'C02':
          return '副秘書長';
        case 'C03':
          return '專職副秘書長';
        case 'C04':
          return '主任';
        case 'C05':
          return '文書主任';
        case 'C06':
          return '總務主任';
        case 'C07':
          return '會計主任';
        case 'C08':
          return '圖書主任';
        case 'C09':
          return '公關主任';
        case 'D01':
          return '執行秘書';
        case 'D02':
          return '資訊顧問';
        case 'E01':
          return '秘書';
        case 'E02':
          return '專員';
        case 'E03':
          return '事務員';
        case 'E04':
          return '資深專員';
        case 'F01':
          return '專職主任';
        case 'F02':
          return '研究員';
        default:
          return '';
      }
    },
  },
  {
    title: '簽核人',
    key: 'SIGN_USER.NAME',
    dataIndex: 'SIGN_USER.NAME',
    render: (_, r) => {
      return r.SIGN_USER?.NAME;
    },
  },
  {
    title: '簽核內容',
    key: 'SIGN_CONTENT',
    dataIndex: 'SIGN_CONTENT',
    width: 180,
  },
  {
    title: '狀態',
    key: 'SIGN_RESULT',
    dataIndex: 'SIGN_RESULT',
    render: (_, r) => {
      switch (r.SIGN_RESULT) {
        case 'O':
          return '未簽核';
        case 'R':
          return '駁回';
        case 'S':
          return '上呈(再送簽)';
        case 'A':
          return '決行(核判)';
        default:
          return '';
      }
    },
  },
  {
    title: '簽核時間',
    key: 'SIGN_TIME',
    dataIndex: 'SIGN_TIME',
    render: (_, r) => {
      return r.SIGN_TIME?.slice(0, 16);
    },
  },
  {
    title: '會簽人員',
    key: 'COUNTERSIGN_BY',
    dataIndex: 'COUNTERSIGN_BY',
    render: (_, r) => {
      return r.COUNTERSIGN_BY.NAME;
    },
  },
];

const IMPLEMENT_COLUMNS = [
  {
    title: '執行人員',
    key: 'DO_USER.NAME',
    dataIndex: 'DO_USER.NAME',
    render: (_, r) => {
      return r.DO_USER?.NAME;
    },
  },
  {
    title: '執行內容',
    key: 'DO_CONTENT',
    dataIndex: 'DO_CONTENT',
  },
  {
    title: '執行時間',
    key: 'DO_TIME',
    dataIndex: 'DO_TIME',
    render: (_, r) => {
      return r.DO_TIME?.slice(0, 16);
    },
  },
  {
    title: '狀態',
    key: 'DO_STATUS',
    dataIndex: 'DO_STATUS',
    render: (_, r) => {
      switch (r.DO_STATUS) {
        case 'Y':
          return '已處理';
        case 'N':
          return '未處理';
        default:
          return '';
      }
    },
  },
];

function AdminLegacyFormHistory(props) {
  console.warn('DBG props', props);

  return (
    <Wrapper>
      <div className="container" id="root_form-history">
        <AdminLineDivider title="簽核紀錄" />
        <Table
          columns={REVIEW_COLUMNS}
          dataSource={props.instance.reviewers}
          pagination={false}
        />
      </div>

      <div className="container" id="root_form-history-execution">
        <AdminLineDivider title="執行紀錄" />
        <Table
          columns={IMPLEMENT_COLUMNS}
          dataSource={props.instance.implements}
          pagination={false}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > div {
    margin: 20px 0px;

    & > div > table {
      width: 100%;
    }

    & > div > table > tr > td {
      padding: 5px 10px;
      text-align: center;
    }
  }

  .container {
    margin-bottom: 40px;
  }

  .row {
    display: flex;
    align-items: center;
  }
`;

export default AdminLegacyFormHistory;
