import React from 'react';
import {Descriptions, Button, InputNumber, message} from 'antd';
import styled from 'styled-components';
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import OrderField from 'rev.sdk.js/Components/OrderField';
import CartList from 'rev.sdk.js/Components/CartList';
import PriceSummary from 'rev.sdk.js/Templates/OrderPage/PriceSummary';
import * as AppActions from '../../../AppActions';
import {
  renderExtraItemsInfo,
  renderDiscountItemsInfo,
} from '../../../Utils/CustomCheckout';
import AdminLineDivider from '../../../Components/AdminLineDivider';
import AdminUserBasicInfo from '../AdminUserBasicInfo';
import UpdateOrderSection from './UpdateOrderSection';
import DiscountItemsSection from './DiscountItemsSection';
import ExtraItemsSection from './ExtraItemsSection';

const L = AppActions.L;

function CustomAdminOrderDetailForm(props) {
  const {context} = props;
  const {instance, values, setValues, path} = context;
  const [member, setMember] = React.useState(null);

  const [editMode, setEditMode] = React.useState(false);
  const [nextFeeValues, setNextFeeValues] = React.useState({
    start_month: instance.start_month,
    end_month: instance.end_month,
  });

  const getOrderType = (instance) => {
    if (instance) {
      const itemName = instance.items[0].product.name;
      const itemLabels = instance.items[0].product.labels;
      const itemHasCourseLabel = itemLabels?.some(
        (l) => l.indexOf('課程') > -1,
      );
      const itemHasActivityLabel = itemLabels?.some(
        (l) => l.indexOf('活動') > -1,
      );

      if (itemName.indexOf('入會費') > -1) {
        return {value: 'infee', label: '入會費'};
      } else if (itemName.indexOf('常年會費') > -1) {
        return {value: 'fee', label: '常年會費'};
      } else if (itemName.indexOf('跨區會費') > -1) {
        return {value: 'crossfee', label: '跨區會費'};
      }

      if (itemHasActivityLabel && itemHasCourseLabel) {
        return {value: 'product', label: '課程/活動'};
      } else if (itemHasActivityLabel) {
        return {value: 'activity', label: '活動'};
      } else if (itemHasCourseLabel) {
        return {value: 'course', label: '課程'};
      }
    }
    return {value: null, label: ''};
  };

  const canEdit =
    getOrderType(instance).value === 'fee' &&
    (!instance.start_month || !instance.end_month);

  return (
    <Wrapper id="rev-AdminOrderDetailForm">
      <div className="buyer-info">
        <AdminUserBasicInfo
          isUprDate={false}
          user_id={instance.owner}
          path={path}
          context={context}
        />
      </div>

      <div className="order-info">
        <AdminLineDivider title="訂單內容" />
        <Descriptions bordered size="small" style={{marginBottom: 10}}>
          <Descriptions.Item label="訂單種類" span={3}>
            {getOrderType(instance).label}
          </Descriptions.Item>
          {getOrderType(instance).value === 'fee' && (
            <>
              <Descriptions.Item label="年度">
                <p>{instance.annual_year || '---'}</p>
              </Descriptions.Item>
              <Descriptions.Item label="起迄">
                {!canEdit ? (
                  <p>
                    {instance.start_month || '---'}月{' - '}
                    {instance.end_month || '---'}月
                  </p>
                ) : !editMode ? (
                  <div>
                    {instance.start_month || '---'}月{' - '}
                    {instance.end_month || '---'}月
                    <Button
                      style={{margin: 10}}
                      shape="circle"
                      size="small"
                      icon={<EditOutlined />}
                      onClick={() => setEditMode(true)}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <div>
                      <InputNumber
                        style={{maxWidth: 100, width: '100%', marginRight: 5}}
                        min="1"
                        max="12"
                        addonAfter="月"
                        value={nextFeeValues.start_month}
                        onChange={(v) =>
                          setNextFeeValues({...nextFeeValues, start_month: v})
                        }
                      />
                      -
                      <InputNumber
                        style={{maxWidth: 100, width: '100%', marginLeft: 5}}
                        min="1"
                        max="12"
                        addonAfter="月"
                        value={nextFeeValues.end_month}
                        onChange={(v) =>
                          setNextFeeValues({...nextFeeValues, end_month: v})
                        }
                      />
                    </div>
                    <div>
                      <Button
                        style={{margin: 10}}
                        shape="circle"
                        size="small"
                        icon={<CheckOutlined />}
                        onClick={async () => {
                          try {
                            AppActions.setLoading(true);

                            await JStorage.updateDocument(
                              'checkout',
                              {
                                order_number: instance.order_number,
                              },
                              {
                                start_month: nextFeeValues.start_month,
                                end_month: nextFeeValues.end_month,
                              },
                            );

                            if (instance.order_id) {
                              await JStorage.updateDocument(
                                'order',
                                {
                                  order_number: instance.order_number,
                                },
                                {
                                  start_month: nextFeeValues.start_month,
                                  end_month: nextFeeValues.end_month,
                                },
                              );
                            }

                            message.success('修改成功');
                            setEditMode(false);

                            window.location.reload();
                          } catch (err) {
                            console.warn(err);
                            message.error('發生錯誤');
                          } finally {
                            AppActions.setLoading(false);
                          }
                        }}
                      />
                      <Button
                        style={{margin: 10}}
                        shape="circle"
                        size="small"
                        icon={<CloseOutlined />}
                        onClick={() => {
                          setNextFeeValues({
                            ...nextFeeValues.end_month,
                            start_month: instance.start_month,
                            end_month: instance.end_month,
                          });

                          setEditMode(false);
                        }}
                      />
                    </div>
                  </div>
                )}
              </Descriptions.Item>
            </>
          )}
          {getOrderType(instance).value === 'crossfee' && (
            <>
              <Descriptions.Item label="執業開始日期">
                <p>
                  {instance.items[0].config.extra_data?.start_date || '---'}
                </p>
              </Descriptions.Item>
              <Descriptions.Item label="執業結束日期">
                <p>{instance.items[0].config.extra_data?.end_date || '---'}</p>
              </Descriptions.Item>
            </>
          )}
        </Descriptions>

        <CartList
          defaultExpand={
            getOrderType(instance).value !== 'fee' &&
            getOrderType(instance).value !== 'infee'
          }
          cartItems={instance.items}
          disabled={true}
        />
        <PriceSummary
          order={instance}
          renderExtraItemsInfo={renderExtraItemsInfo}
          renderDiscountItemsInfo={renderDiscountItemsInfo}
        />
      </div>

      <div className="receiver-info">
        <AdminLineDivider title={L('receiver_info')} />
        <OrderField name="姓名" value={instance.receiver_name} />
        <OrderField name="行動電話" value={instance.receiver_phone} />
        <OrderField name="電子信箱" value={instance.receiver_email} />
        <OrderField
          name="地址"
          value={`${instance.receiver_zip} ${instance.receiver_city} ${instance.receiver_district} ${instance.receiver_address}`}
        />
        <OrderField
          name="市話"
          value={`${instance.receiver_tel} 分機 ${instance.receiver_tel_ext}`}
        />
      </div>

      <div className="discounts-info">
        <AdminLineDivider title="金額減項" />
        <DiscountItemsSection {...props} />
      </div>

      <div className="fees-info">
        <AdminLineDivider title="金額加項" />
        <ExtraItemsSection {...props} />
      </div>

      <div className="receipt-info">
        <AdminLineDivider title="收據資料" />
        <UpdateOrderSection {...props} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 15px 0px;

  & > div {
    margin-bottom: 40px;

    & > h2 {
      margin-bottom: 10px;
    }
  }
`;

export default CustomAdminOrderDetailForm;
