import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import DataJson from '../../../data.json';

function AdminLegacySelectFormField(props) {
  const {formData, schema} = props;
  const [url, setUrl] = React.useState(null);

  React.useEffect(() => {
    async function fetchFormInstance(params) {
      try {
        AppActions.setLoading(true);

        const resp = await JStorage.fetchOneDocument(
          'form',
          params.queryType === 'document_out'
            ? {
                is_old: true,
                type: params.queryType,
                MISSIVE_NO: params.MISSIVE_NO,
              }
            : {
                is_old: true,
                type: params.queryType,
                SERIAL_NO: params.SERIAL_NO,
              },
        );

        if (resp.id) {
          setUrl(
            params.queryType === 'document_out'
              ? `${DataJson.webHost}/admin/legacy-posts/?action=detail&id=${resp.id}`
              : `${DataJson.webHost}/admin/legacy-receives/?action=detail&id=${resp.id}`,
          );
        }
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    }

    if (!url) {
      if (schema.queryType === 'document_out' && !!formData.MISSIVE_NO) {
        fetchFormInstance({
          queryType: schema.queryType,
          MISSIVE_NO: formData.MISSIVE_NO,
        });
      }

      if (schema.queryType === 'document_in' && !url && !!formData.SERIAL_NO) {
        fetchFormInstance({
          queryType: schema.queryType,
          SERIAL_NO: formData.SERIAL_NO,
        });
      }
    }
  }, [schema.queryType, formData, url]);

  return (
    <Wrapper>
      <div>{schema.title}</div>
      {schema.queryType === 'document_out' && url && (
        <div className="field">
          <label>{formData.MISSIVE_NO}</label>
          <a href={url} target="_blank" rel="noreferrer">
            查看詳情
          </a>
        </div>
      )}

      {schema.queryType === 'document_in' && url && (
        <div className="field">
          <label>{formData.SERIAL_NO}</label>
          <a href={url} target="_blank" rel="noreferrer">
            查看詳情
          </a>
        </div>
      )}

      {!url && (
        <div className="field">
          <label>無</label>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .field {
    display: flex;
    align-items: center;
    border: 1px solid var(--adminBorderColor);
    border-radius: 4px;
    padding: 4px 11px;

    & > label {
      margin-right: 10px;
    }
  }

  & > div {
    padding-bottom: 8px;
    line-height: 1.5715;
  }
`;

export default AdminLegacySelectFormField;
