import React from 'react';
import styled from 'styled-components';
import {Table, Button} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as OrderUtil from 'rev.sdk.js/Utils/OrderUtil';
import * as OrderCustomUtil from '../../Utils/OrderCustomUtil';
import * as UserUtil from '../../Utils/UserUtil';
import * as ProductUtil from '../../Utils/ProductUtil';
import AdminLinkButton from './AdminLinkButton';

export default function AdminOrderFeeList(props) {
  const {context} = props;
  const {instance} = context;
  const [records, setRecords] = React.useState([]);
  const [withdrawRecords, setWithdrawRecords] = React.useState([]);
  const [stopRecords, setStopRecords] = React.useState([]);

  React.useEffect(() => {
    if (instance.id) {
      async function fetchRecords(pid) {
        try {
          const checkouts = await JStorage.fetchDocuments(
            'checkout',
            {
              'items.0.product.id': {
                $in: [...ProductUtil.FEE_PRODUCTS].map((id) => ({$oid: id})),
              },
              owner: instance.owner,
            },
            ['-created'],
            {offset: 0, limit: 10000},
            {
              order_id: 1,
              order_number: 1,
              items: 1,
              annual_year: 1,
              buyer_name: 1,
              total: 1,
              payment_subtype: 1,
              created: 1,
              start_month: 1,
              end_month: 1,
            },
          );

          const ordersResp = await OrderCustomUtil.mergeCheckoutJStorageRespWithOrder(
            checkouts,
            {order_id: 1, order_number: 1, buyer_name: 1, payment_status: 1},
          );

          setRecords(ordersResp.results);

          if (ordersResp.total > 0) {
            const withdraws = await JStorage.fetchAllDocuments(
              'withdraw_record',
              {
                user: instance.owner,
                order_number: {
                  $in: ordersResp.results.map((o) => o.order_number),
                },
              },
            );

            const stops = await JStorage.fetchAllDocuments('stop_record', {
              user: instance.owner,
              order_number: {
                $in: ordersResp.results.map((o) => o.order_number),
              },
            });

            setWithdrawRecords(withdraws);
            setStopRecords(stops);
          }
        } catch (err) {
          console.warn(err);
        }
      }

      fetchRecords(instance.id);
    }
  }, [instance.id, instance.owner]);

  return (
    <Wrapper>
      <h3 className="title">會費明細</h3>
      {records.length > 0 && (
        <Table
          columns={[
            {
              title: '訂單編號 / 流水號',
              key: 'order_number',
              dataIndex: 'order_number',
              render: (_, r) => {
                const title = r.order_id
                  ? `${r.order_id}`
                  : `${r.order_number}*`;
                const url = OrderUtil.getAdminUrlFromMergedCheckout(r);
                const backPath = '/admin/orders-fee';

                return (
                  <AdminLinkButton
                    style={{maxWidth: 150, paddingLeft: 0}}
                    title={title}
                    url={`${url}&backPath=${backPath}`}
                  />
                );
              },
            },
            {
              title: '名稱',
              key: '',
              dataIndex: '',
              render: (_, r) => {
                const {product, config} = r.items[0];

                return (
                  <div>
                    {(() => {
                      if (product.name.indexOf('常年會費') > -1) {
                        return (
                          <div>
                            <div>
                              {r.annual_year}
                              {product.name}
                            </div>
                            {r.start_month &&
                              r.end_month &&
                              `${r.start_month}月 - ${r.end_month}月`}
                          </div>
                        );
                      } else if (product.name.indexOf('跨區會費') > -1) {
                        return (
                          <div>
                            <div>
                              {product.name} {config.variants[0]?.choice}
                            </div>
                            <div>
                              執業開始日期：{config.extra_data?.start_date}
                            </div>
                            <di>執業結束日期：{config.extra_data?.end_date}</di>
                          </div>
                        );
                      }
                    })()}
                  </div>
                );
              },
            },
            {
              title: '應收',
              key: 'total',
              dataIndex: 'total',
            },
            {
              title: '實收',
              key: 'total',
              dataIndex: 'total',

              render: (_, r) => (r.payment_status === 'success' ? r.total : 0),
            },
            {
              title: '付款狀態',
              key: 'payment_status',
              dataIndex: 'payment_status',
              render: (_, r) =>
                Cart.PAYMENT_STATUS_DISPLAY[r.payment_status]?.label || '---',
            },
            {
              title: '付款方式',
              key: 'payment_subtype',
              dataIndex: 'payment_subtype',
              render: (_, r) =>
                r ? Cart.PAYMENT_SUBTYPE_DISPLAY[r.payment_subtype]?.label : '',
            },
          ]}
          dataSource={records}
          pagination={{
            size: 'small',
            showTotal: (total) => `共 ${total} 筆`,
          }}
          expandable={{
            defaultExpandAllRows: true,
            expandedRowRender: (r) => {
              const targetWithdrawRecords = withdrawRecords.filter(
                (wr) => wr.order_number === r.order_number,
              );
              const targetStopRecords = stopRecords.filter(
                (sr) => sr.order_number === r.order_number,
              );

              return (
                <div className="expand-wrapper">
                  {targetWithdrawRecords.length > 0 && (
                    <div>
                      {targetWithdrawRecords.map((wr) => (
                        <div>
                          [退會紀錄]{' '}
                          <span>
                            {wr.name} {wr.withdraw_date}{' '}
                            {UserUtil.getUserInoutLeaveReasonLabel(
                              wr.leave_reason,
                            )}{' '}
                            (公會應退會員金額：{wr.price})
                          </span>
                          <Button
                            type="link"
                            target="_blank"
                            href={`/admin/users-withdraw-record/?action=detail&id=${wr.id}`}>
                            查看
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                  {targetStopRecords.length > 0 && (
                    <div>
                      {targetStopRecords.map((sr) => (
                        <div>
                          [停止紀錄]{' '}
                          <span>
                            {sr.name} {sr.stop_date}{' '}
                            {UserUtil.getUserInoutLeaveReasonLabel(
                              sr.leave_reason,
                            )}{' '}
                            (公會應退會員金額：{sr.price})
                          </span>
                          <Button
                            type="link"
                            target="_blank"
                            href={`/admin/users-stop-record/?action=detail&id=${sr.id}`}>
                            查看
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            },
            rowExpandable: (r) => {
              const targetWithdrawRecords = withdrawRecords.filter(
                (wr) => wr.order_number === r.order_number,
              );
              const targetStopRecords = stopRecords.filter(
                (sr) => sr.order_number === r.order_number,
              );

              return (
                targetWithdrawRecords.length > 0 || targetStopRecords.length > 0
              );
            },
          }}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  & > .title {
    margin-bottom: 20px;
  }

  & > .container {
  }

  .expand-wrapper {
    padding-left: 50px;
  }
`;
