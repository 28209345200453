import React from 'react';
import styled from 'styled-components';
import {Button, Modal} from 'antd';
import {useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';
import * as AppActions from '../../AppActions';

export default function AdminReceiveAdvancedSearchButton(props) {
  const [visible, setVisible] = React.useState(false);
  const [instance, setInstance] = React.useState({});

  return (
    <Wrapper>
      <Button
        type="default"
        style={{marginRight: 10}}
        onClick={() => setVisible(true)}>
        {Object.keys(instance).length > 0 && (
          <span style={{color: 'var(--primaryColor)'}}>✽</span>
        )}
        進階搜尋
      </Button>
      <Button
        onClick={() => {
          setInstance({});
          props.setQueryState((prev) => {
            return {
              ...prev,
              extraQueries: {},
              paging: {offset: 0, limit: prev.paging.limit},
            };
          });
        }}>
        清除進階搜尋條件
      </Button>
      <AdvancedSearchModal
        {...props}
        visible={visible}
        instance={instance}
        setVisible={setVisible}
        setInstance={setInstance}
      />
    </Wrapper>
  );
}

function AdvancedSearchModal(props) {
  return (
    <Modal
      title={null}
      footer={null}
      width={650}
      bodyStyle={{padding: 0}}
      visible={props.visible}
      onCancel={() => props.setVisible(false)}>
      <ModalContent {...props} />
    </Modal>
  );
}

function ModalContent(props) {
  const {queryState, instance, setQueryState, setInstance} = props;

  const [staffs] = useOutlet('staffs');

  const onSubmit = async (formData, extValues) => {
    try {
      const _extraQueries = transformQueries({
        originalQueries: {$and: []},
        formData,
        extra: {staffs},
      });

      setQueryState((prev) => {
        const nextExtraQuery = {...prev.extraQueries, ..._extraQueries};

        const filterOutQueryKeys = Object.keys(prev.extraQueries)
          .map((q) => {
            if (!Object.keys(_extraQueries).find((qk) => qk === q)) {
              return q;
            } else {
              return null;
            }
          })
          .filter((k) => !!k);

        filterOutQueryKeys.forEach((k) => {
          delete nextExtraQuery[k];
        });

        return {
          ...prev,
          extraQueries: nextExtraQuery,
          paging: {offset: 0, limit: prev.paging.limit},
        };
      });

      props.setVisible(false);
    } catch (err) {
      console.warn(err);
    } finally {
      AppActions.setLoading(false);
    }
  };

  const onClean = () => {
    setInstance({});
    setQueryState((prev) => ({
      ...prev,
      extraQueries: {},
      paging: {offset: 0, limit: prev.paging.limit},
    }));
  };

  const rjsfProps = {
    widgets: {},
  };

  return (
    <ModalContentWrapper>
      <div className="title-container">
        <h2>收文管理 進階搜尋</h2>
        <Button type="link" onClick={onClean}>
          全部清除
        </Button>
      </div>
      <GenericForm
        instance={instance}
        schema={FORM_SPEC.schema}
        uiSchema={FORM_SPEC.uiSchema}
        rjsfProps={rjsfProps}
        onChange={(formData) => setInstance(formData)}
        onSubmit={onSubmit}
      />
    </ModalContentWrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ModalContentWrapper = styled(ModalCustomStyle)`
  padding: 20px;

  & > .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
`;

const FORM_SPEC = {
  schema: {
    title: '',
    type: 'object',
    properties: {
      receive_date_start: {
        type: 'string',
        title: '收文日期（起）',
      },
      receive_date_end: {
        type: 'string',
        title: '收文日期（迄）',
      },
      paperwork_id_start: {
        type: 'string',
        title: '收文編號（起）',
      },
      paperwork_id_end: {
        type: 'string',
        title: '收文編號（迄）',
      },
      stages: {
        type: 'array',
        title: '狀態',
        uniqueItems: true,
        items: {
          type: 'string',
          enum: [
            'edit',
            'review_1',
            'review_2',
            'review_3',
            'review_4',
            'execution',
            'execution_direct',
            'success',
            'abort',
            'legacy-0',
            'legacy-1',
            'legacy-2',
            'legacy-3',
          ],
          enumNames: [
            '編輯',
            '會簽1輪',
            '會簽2輪',
            '會簽3輪',
            '會簽4輪',
            '執行',
            '逕付執行',
            '完成',
            '退簽',
            '建檔(舊)',
            '簽核(舊)',
            '執行(舊)',
            '結案(舊)',
          ],
        },
      },
      organs: {
        type: 'string',
        title: '發文機關',
      },
      title: {
        type: 'string',
        title: '主旨',
      },
      reviewer_name: {
        type: 'string',
        title: '簽核人員',
      },
      executor_name: {
        type: 'string',
        title: '執行人員',
      },
      implement_name: {
        type: 'string',
        title: '擬辦人員',
      },
      keywords: {
        type: 'string',
        title: '關鍵字',
      },
    },
    dependencies: {
      receive_date_start: ['receive_date_end'],
      paperwork_id_start: ['paperwork_id_end'],
      text_number_start: ['text_number_end'],
    },
  },
  uiSchema: {
    receive_date_start: {
      'ui:help': '格式 YYYY-MM-DD，例如：2024-01-01',
    },
    receive_date_end: {
      'ui:help': '格式 YYYY-MM-DD，例如：2024-01-01',
    },
  },
};

function transformQueries({originalQueries, formData, extra}) {
  const nextQueries = {...originalQueries};

  if (!!formData.receive_date_start && !!formData.receive_date_end) {
    nextQueries.$and.push({
      $or: [
        {
          date: {
            $gte: formData.receive_date_start,
            $lte: formData.receive_date_end,
          },
        },
        {
          RECEIVE_DATE: {
            $gte: formData.receive_date_start,
            $lte: formData.receive_date_end,
          },
        },
      ],
    });
  }

  if (!!formData.paperwork_id_start && !!formData.paperwork_id_end) {
    nextQueries.$and.push({
      $or: [
        {
          paperwork_id: {
            $gte: formData.paperwork_id_start,
            $lte: formData.paperwork_id_end,
          },
        },
        {
          SERIAL_NO: {
            $gte: formData.paperwork_id_start,
            $lte: formData.paperwork_id_end,
          },
        },
      ],
    });
  }

  if (
    !!formData.stages &&
    Array.isArray(formData.stages) &&
    formData.stages.length > 0
  ) {
    const _stages = [];
    const _legacy_stages = [];

    formData.stages.forEach((stage) => {
      if (stage === 'legacy-0') {
        _legacy_stages.push(0);
      } else if (stage === 'legacy-1') {
        _legacy_stages.push(1);
      } else if (stage === 'legacy-2') {
        _legacy_stages.push(2);
      } else if (stage === 'legacy-3') {
        _legacy_stages.push(3);
      } else {
        _stages.push(stage);
      }
    });

    nextQueries.$and.push({
      $or: [{stage: {$in: _stages}}, {PROGRESS: {$in: _legacy_stages}}],
    });
  }

  if (!!formData.organs) {
    nextQueries.$and.push({
      $or: [
        {organs: {$regex: formData.organs}},
        {FROM_ORG: {$regex: formData.organs}},
      ],
    });
  }

  if (!!formData.title) {
    nextQueries.$and.push({
      $or: [
        {title: {$regex: formData.title}},
        {DRIFT: {$regex: formData.title}},
      ],
    });
  }

  if (!!formData.reviewer_name) {
    const targetStaffs = extra.staffs.filter(
      (staff) => staff.name.indexOf(formData.reviewer_name) > -1,
    );

    if (
      !!targetStaffs &&
      Array.isArray(targetStaffs) &&
      targetStaffs.length > -1
    ) {
      const ids = targetStaffs.map((staff) => staff.id);
      nextQueries.$and.push({
        $or: [
          {review_1: {$elemMatch: {user_id: {$in: ids}}}},
          {review_2: {$elemMatch: {user_id: {$in: ids}}}},
          {review_3: {$elemMatch: {user_id: {$in: ids}}}},
          {review_4: {$elemMatch: {user_id: {$in: ids}}}},
          {
            reviewers: {
              $elemMatch: {
                'SIGN_USER.NAME': {$regex: formData.reviewer_name},
              },
            },
          },
        ],
      });
    } else {
      nextQueries.$and.push({
        reviewers: {
          $elemMatch: {'SIGN_USER.NAME': {$regex: formData.reviewer_name}},
        },
      });
    }
  }

  if (!!formData.executor_name) {
    const targetStaffs = extra.staffs.filter(
      (staff) => staff.name.indexOf(formData.executor_name) > -1,
    );

    if (
      !!targetStaffs &&
      Array.isArray(targetStaffs) &&
      targetStaffs.length > -1
    ) {
      const ids = targetStaffs.map((staff) => staff.id);

      nextQueries.$and.push({
        $or: [
          {executor: {$in: ids}},
          {
            history_execution: {
              $elemMatch: {
                staff: {$in: ids},
              },
            },
          },
          {
            implements: {
              $elemMatch: {
                'DO_USER.NAME': {$regex: formData.executor_name},
              },
            },
          },
        ],
      });
    } else {
      nextQueries.$and.push({
        implements: {
          $elemMatch: {'DO_USER.NAME': {$regex: formData.executor_name}},
        },
      });
    }
  }

  if (!!formData.implement_name) {
    const targetStaffs = extra.staffs.filter(
      (staff) => staff.name.indexOf(formData.implement_name) > -1,
    );

    if (
      !!targetStaffs &&
      Array.isArray(targetStaffs) &&
      targetStaffs.length > -1
    ) {
      const ids = targetStaffs.map((staff) => staff.id);
      nextQueries.$and.push({
        $or: [
          {implement: {$in: ids}},
          {
            'PROC_BY.NAME': {$regex: formData.implement_name},
          },
        ],
      });
    } else {
      nextQueries.$and.push({
        'PROC_BY.NAME': {$regex: formData.implement_name},
      });
    }
  }

  if (!!formData.keywords) {
    nextQueries.$and.push({
      $or: [
        {keywords: {$regex: formData.keywords}},
        {KEYWORD: {$regex: formData.keywords}},
      ],
    });
  }

  return nextQueries;
}
