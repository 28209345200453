import React from 'react';
import styled from 'styled-components';
import {Button, Modal} from 'antd';
import {useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';
import * as AppActions from '../../AppActions';

export default function AdminProductAdvancedSearchButton(props) {
  const [visible, setVisible] = React.useState(false);
  const [instance, setInstance] = React.useState({});

  return (
    <Wrapper>
      <Button
        type="default"
        style={{marginRight: 10}}
        onClick={() => setVisible(true)}>
        {Object.keys(instance).length > 0 && (
          <span style={{color: 'var(--primaryColor)'}}>✽</span>
        )}
        進階搜尋
      </Button>
      <Button
        onClick={() => {
          setInstance({});
          props.setQueryState((prev) => {
            return {
              ...prev,
              extraQueries: {},
              paging: {offset: 0, limit: prev.paging.limit},
            };
          });
        }}>
        清除進階搜尋條件
      </Button>
      <AdvancedSearchModal
        {...props}
        visible={visible}
        instance={instance}
        setVisible={setVisible}
        setInstance={setInstance}
      />
    </Wrapper>
  );
}

function AdvancedSearchModal(props) {
  return (
    <Modal
      title={null}
      footer={null}
      width={650}
      bodyStyle={{padding: 0}}
      visible={props.visible}
      onCancel={() => props.setVisible(false)}>
      <ModalContent {...props} />
    </Modal>
  );
}

function ModalContent(props) {
  const {queryState, instance, setQueryState, setInstance} = props;

  const [staffs] = useOutlet('staffs');

  const onSubmit = async (formData, extValues) => {
    try {
      const _extraQueries = transformQueries({
        originalQueries: {$and: []},
        formData,
        extra: {staffs},
      });

      setQueryState((prev) => {
        const nextExtraQuery = {...prev.extraQueries, ..._extraQueries};

        const filterOutQueryKeys = Object.keys(prev.extraQueries)
          .map((q) => {
            if (!Object.keys(_extraQueries).find((qk) => qk === q)) {
              return q;
            } else {
              return null;
            }
          })
          .filter((k) => !!k);

        filterOutQueryKeys.forEach((k) => {
          delete nextExtraQuery[k];
        });

        return {
          ...prev,
          extraQueries: nextExtraQuery,
          paging: {offset: 0, limit: prev.paging.limit},
        };
      });

      props.setVisible(false);
    } catch (err) {
      console.warn(err);
    } finally {
      AppActions.setLoading(false);
    }
  };

  const onClean = () => {
    setInstance({});
    setQueryState((prev) => ({
      ...prev,
      extraQueries: {},
      paging: {offset: 0, limit: prev.paging.limit},
    }));
  };

  const rjsfProps = {
    widgets: {},
  };

  return (
    <ModalContentWrapper>
      <div className="title-container">
        <h2>課程/活動全覽 進階搜尋</h2>
        <Button type="link" onClick={onClean} style={{marginLeft: 10}}>
          全部清除
        </Button>
      </div>
      <GenericForm
        instance={instance}
        schema={FORM_SPEC.schema}
        uiSchema={FORM_SPEC.uiSchema}
        rjsfProps={rjsfProps}
        onChange={(formData) => setInstance(formData)}
        onSubmit={onSubmit}
      />
    </ModalContentWrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ModalContentWrapper = styled(ModalCustomStyle)`
  padding: 20px;

  & > .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
`;

const FORM_SPEC = {
  schema: {
    title: '',
    type: 'object',
    properties: {
      session_date_start: {
        type: 'string',
        title: '場次日期（起）',
      },
      session_date_end: {
        type: 'string',
        title: '場次日期（迄）',
      },
      onshelf_date_start: {
        type: 'string',
        title: '上稿日期（起）',
      },
      onshelf_date_end: {
        type: 'string',
        title: '上稿日期（迄）',
      },
      name: {
        type: 'string',
        title: '名稱',
      },
      serial_number: {
        type: 'string',
        title: '編號 / 序號',
      },
      keywords: {
        type: 'string',
        title: '關鍵字',
      },
      legacy_course_types: {
        type: 'array',
        title: '舊系統進修課程類別',
        uniqueItems: true,
        items: {
          type: 'string',
          enum: ['OJT', 'RECOG', 'SEMINAR'],
          enumNames: ['在職進修', '採認進修', '座談研討會'],
        },
      },
      legacy_activity_types: {
        type: 'array',
        title: '舊系統一般活動類別',
        uniqueItems: true,
        items: {
          type: 'string',
          enum: [
            'N/A',
            'A01',
            'A02',
            'A03',
            'A04',
            'A05',
            'A06',
            'A07',
            'A08',
            'A09',
            'A10',
            'A11',
            'A12',
            'A13',
            'A14',
            'A15',
            'A16',
          ],
          enumNames: [
            '請選擇',
            '座談研討會',
            '接待活動',
            '公聽會',
            '研習活動',
            '拜會活動',
            '午間小品',
            '次團活動',
            '休閒活動',
            '聯誼餐會',
            '記者會',
            '說明會',
            '旅遊',
            '律師節活動',
            '外部活動',
            '公會場地外租',
            '其他',
          ],
        },
      },
    },
    dependencies: {
      session_date_start: ['session_date_end'],
      onshelf_date_start: ['onshelf_date_end'],
    },
  },
  uiSchema: {
    session_date_start: {
      'ui:help': '格式 YYYY-MM-DD，例如：2024-01-01',
    },
    session_date_end: {
      'ui:help': '格式 YYYY-MM-DD，例如：2024-01-01',
    },
    onshelf_date_start: {
      'ui:help': '格式 YYYY-MM-DD，例如：2024-01-01',
    },
    onshelf_date_end: {
      'ui:help': '格式 YYYY-MM-DD，例如：2024-01-01',
    },
  },
};

function transformQueries({originalQueries, formData, extra}) {
  const nextQueries = {...originalQueries};

  if (!!formData.session_date_start && !!formData.session_date_end) {
    const end_date_timestamp =
      new Date(formData.session_date_end).getTime() + 24 * 60 * 60 * 1000;

    const end_date = new Date(end_date_timestamp).toLocaleDateString('sv');

    nextQueries.$and.push({
      $or: [
        {
          'session.date': {
            $gte: formData.session_date_start,
            $lte: formData.session_date_end,
          },
        },
        {
          //legacy course
          course_session: {
            $elemMatch: {
              SESSION_TIME: {
                $gte: formData.session_date_start,
                $lte: end_date,
              },
            },
          },
        },
        {
          //legacy activity
          activity_session: {
            $elemMatch: {
              EFF_DATE: {$gte: formData.session_date_start},
              EXPR_DATE: {$lte: formData.session_date_end},
            },
          },
        },
      ],
    });
  }

  if (!!formData.onshelf_date_start && !!formData.onshelf_date_end) {
    const end_date_timestamp =
      new Date(formData.onshelf_date_end).getTime() + 24 * 60 * 60 * 1000;

    const end_date = new Date(end_date_timestamp).toLocaleDateString('sv');

    nextQueries.$and.push({
      $or: [
        {
          onshelf_start_date: {$gte: formData.onshelf_date_start},
          onshelf_end_date: {$lte: formData.onshelf_date_end},
        },
        {
          //legacy course + legacy activity
          EFF_DATE: {$gte: formData.onshelf_date_start},
          EXPR_DATE: {$lte: end_date},
        },
      ],
    });
  }

  if (!!formData.name) {
    nextQueries.$and.push({
      $or: [
        {name: {$regex: formData.name}},
        {NAME: {$regex: formData.name}}, //legacy cource + legacy ecourse
        {ACT_NAME: {$regex: formData.name}}, //legacy activity
      ],
    });
  }

  if (!!formData.serial_number) {
    nextQueries.$and.push({
      $or: [
        {serial_number: {$regex: formData.serial_number}},
        {CLASS_NO: {$regex: formData.serial_number}}, //legacy course （type: string）
        {CLASS_NO: Number(formData.serial_number)}, //legacy ecourse (type: integer)
        {ID: Number(formData.serial_number)}, //legacy activity (type: integer)
      ],
    });
  }

  if (
    !!formData.legacy_course_types &&
    Array.isArray(formData.legacy_course_types) &&
    formData.legacy_course_types.length > 0
  ) {
    nextQueries.$and.push({
      TYPE: {$in: formData.legacy_course_types},
    });
  }

  if (
    !!formData.legacy_activity_types &&
    Array.isArray(formData.legacy_activity_types) &&
    formData.legacy_activity_types.length > 0
  ) {
    nextQueries.$and.push({
      ACT_TYPE: {$in: formData.legacy_activity_types},
    });
  }

  if (!!formData.keywords) {
    nextQueries.$and.push({
      $or: [
        //名稱
        {name: {$regex: formData.keywords}},
        {NAME: {$regex: formData.keywords}},
        {ACT_NAME: {$regex: formData.keywords}},
        //主協辦單位
        {unit: {$regex: formData.keywords}},
        {ORGANIZER: {$regex: formData.keywords}},
        //說明
        {intro: {$regex: formData.keywords}},
        {spec: {$regex: formData.keywords}},
        {DESCRIPTION: {$regex: formData.keywords}},
        //執行情形
        {execution_note: {$regex: formData.keywords}},
        {CEXECUTE: {$regex: formData.keywords}},
        //關鍵字
        {searchText: {$regex: formData.keywords}},
      ],
    });
  }

  return nextQueries;
}
